import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentConfig } from '@shared-models/environment-config';
import { EnvironmentService } from '@shared/infrastructure/environment.service';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { FileAssociation } from '@shared-models/file-association';
import { WorkflowRequest } from '@shared-models/workflow-request';
import { FileUploadDto } from '@shared-models/file-upload.dto';
import { FileMetaData } from '@shared-models/file-meta-data';
import { PartNumberRevision } from '@shared-models/part-number-revision';
import { PaginationRequest } from '@shared-models/pagination-request';
import DOMPurify from 'dompurify';

const pageSize = 5;
@Injectable()
export class PartNumberRevisionApiService {
    environmentConfig: EnvironmentConfig;

    constructor(
        private http: HttpClient,
        environmentService: EnvironmentService
    ) {
        this.environmentConfig = environmentService.getEnvironmentConfig();
    }

    getPartNumberRevisions(partNumber: string): Observable<any> {
        const url = DOMPurify.sanitize(`${this.environmentConfig.apiBaseUrl}/productfile/v1/productfile/assets/${partNumber}`);
        return this.http.get(url).pipe(
            map((response: any) => response.data)
        );
    }

    getPendingFiles(paginationRequest: PaginationRequest): Observable<FileAssociation> {
        let offset = 0;
        if(paginationRequest && paginationRequest?.pageIndex) {
            offset = paginationRequest.pageIndex * pageSize;
        }
        const url = `${this.environmentConfig.apiBaseUrl}/productfile/v1/productfile/assets/validatorFiles?offset=${offset}&limit=${pageSize}`;

        return this.http.get<FileAssociation>(url).pipe(
            map((response: FileAssociation) => response)
        );
    }

    getFilesToValidate(partNumberRevision: PartNumberRevision): Observable<FileAssociation> {
        let offset = 0;
        if(partNumberRevision && partNumberRevision.pageIndex) {
            offset = partNumberRevision.pageIndex * pageSize;
        }
        const queryParams = `partNumber=${partNumberRevision.partNumber}&revision=${partNumberRevision.productRevision}&offset=${offset}&limit=${pageSize}`;
        const url = `${this.environmentConfig.apiBaseUrl}/productfile/v1/productfile/assets/validatorFilesByPartNumberRevision?${queryParams}`;

        return this.http.get<FileAssociation>(url).pipe(
            map((response: FileAssociation) => response)
        );
    }

    getFilesOriginatedPendingValidation(partNumberRevision: PartNumberRevision): Observable<FileAssociation> {
        let offset = 0;
        if(partNumberRevision && partNumberRevision.pageIndex) {
            offset = partNumberRevision.pageIndex * pageSize;
        }
        const queryParams = `partNumber=${partNumberRevision.partNumber}&revision=${partNumberRevision.productRevision}&offset=${offset}&limit=${pageSize}`;
        const url = `${this.environmentConfig.apiBaseUrl}/productfile/v1/productfile/assets/originatorPendingFiles?${queryParams}`;

        return this.http.get<FileAssociation>(url).pipe(
            map((response: FileAssociation) => response)
        );
    }

    getOriginatorFiles(partNumberRevision: PartNumberRevision): Observable<FileAssociation> {
        let offset = 0;
        if(partNumberRevision && partNumberRevision.pageIndex) {
            offset = partNumberRevision.pageIndex * pageSize;
        }
        const queryParams = `partNumber=${partNumberRevision.partNumber}&revision=${partNumberRevision.productRevision}&offset=${offset}&limit=${pageSize}&status=active`;
        const url = `${this.environmentConfig.apiBaseUrl}/productfile/v1/productfile/assets/associatedFiles?${queryParams}`;

        return this.http.get<FileAssociation>(url).pipe(
            map((response: FileAssociation) => response)
        );
    }

    getOriginatorPastFiles(partNumberRevision: PartNumberRevision): Observable<FileAssociation> {
        let offset = 0;
        if(partNumberRevision && partNumberRevision.pageIndex) {
            offset = partNumberRevision.pageIndex * pageSize;
        }
        const queryParams = `partNumber=${partNumberRevision.partNumber}&revision=${partNumberRevision.productRevision}&offset=${offset}&limit=${pageSize}&status=notactive`;
        const url = `${this.environmentConfig.apiBaseUrl}/productfile/v1/productfile/assets/associatedFiles?${queryParams}`;

        return this.http.get<FileAssociation>(url).pipe(
            map((response: FileAssociation) => response)
        );
    }

    submitValidationAction(reqData: WorkflowRequest) {
        const url = `${this.environmentConfig.apiBaseUrl}/productfile/v1/productfile/assets/workflow`;

        return this.http.post(url, reqData);
    }

    addFileAssociation(fileUploadDto: FileUploadDto) {
       const fileMetaData = {
                fileName: fileUploadDto.fileMetaData.fileName,
                partNumber: fileUploadDto.fileMetaData.partNumber,
                partName: fileUploadDto.fileMetaData.partName,
                productDescription: fileUploadDto.fileMetaData.productDescription,
                revision: fileUploadDto.fileMetaData.revision,
                originator: fileUploadDto.fileMetaData.originator,
                validator: fileUploadDto.fileMetaData.validator
        } as FileMetaData;

        const metaDataUrl = `${this.environmentConfig.apiBaseUrl}/productfile/v1/productfile/assets/fileDetails`;
        return this.http.post(metaDataUrl, fileMetaData);
    }

    replaceAndAddFileAssociation(fileUploadDto: FileUploadDto) {
        const workflowUrl = `${this.environmentConfig.apiBaseUrl}/productfile/v1/productfile/assets/workflow`;
        const fileDetailsUrl = `${this.environmentConfig.apiBaseUrl}/productfile/v1/productfile/assets/fileDetails`;
        const fileMetaData = {
            fileName: fileUploadDto.fileMetaData.fileName,
            partNumber: fileUploadDto.fileMetaData.partNumber,
            partName: fileUploadDto.fileMetaData.partName,
            productDescription: fileUploadDto.fileMetaData.productDescription,
            revision: fileUploadDto.fileMetaData.revision,
            originator: fileUploadDto.fileMetaData.originator,
            validator: fileUploadDto.fileMetaData.validator,
            replacedFileId: fileUploadDto.workflowRequest.workflowDetails[0].fileId
        } as FileMetaData;

        return this.http.post(fileDetailsUrl, fileMetaData).pipe(
                map((workflowUrlResponse: any) => workflowUrlResponse.data),
                mergeMap(() => this.http.post(workflowUrl, fileUploadDto.workflowRequest)
            ));
    }
}
