import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ProductFileHttpInterceptor } from './shared/infrastructure/productfile-http-interceptor';
import { ConfigurationFactory } from './shared/infrastructure/configuration.factory';
import { ConfigurationService } from './shared/infrastructure/configuration.service';
import { EnvironmentService } from './shared/infrastructure/environment.service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { PagesModule } from './pages/pages.module';
import { StoreModule } from '@ngrx/store';
import { reducers} from '@shared-store/app.store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { PartNumberRevisionEffects } from '@shared-store/effects/part-number-revision.effects';
import { PartNumberRevisionSandboxService } from '@shared-services/part-number-revision-sandbox.service';
import { PartNumberRevisionApiService } from '@shared-api-services/part-number-revision.api.service';
import { WebComponentService } from '@shared-services/web-component.service.component';
import { UserEffects } from '@shared-store/effects/user.effects';
import { UserApiService } from '@shared-api-services/user-api.service';
import { UserSandboxService } from '@shared-services/user-sandbox.service';
import { AuthComponent } from './components/auth/auth.component';
import { UploadService } from '@shared-api-services/upload-service';
import { SharedModalService } from '@shared-services/shared-modal.service';

@NgModule({ declarations: [
        AppComponent,
        AuthComponent
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        PagesModule,
        StoreModule.forRoot(reducers),
        StoreDevtoolsModule.instrument({ maxAge: 60, connectInZone: true }),
        EffectsModule.forRoot([
            PartNumberRevisionEffects,
            UserEffects
        ])], providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: ConfigurationFactory.load,
            deps: [
                ConfigurationService,
                EnvironmentService
            ],
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ProductFileHttpInterceptor,
            multi: true
        },
        PartNumberRevisionSandboxService,
        PartNumberRevisionApiService,
        UserSandboxService,
        UserApiService,
        UploadService,
        WebComponentService,
        SharedModalService,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }
