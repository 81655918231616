import { Component, OnDestroy, OnInit } from '@angular/core';
import { Navigation, Router } from '@angular/router';
import { FileAssociation } from '@shared-models/file-association';
import { FileReviewSectionType } from '@shared-models/file-review-section-type';
import { PartNumberRevision } from '@shared-models/part-number-revision';
import { User } from '@shared-models/user';
import { PartNumberRevisionSandboxService } from '@shared-services/part-number-revision-sandbox.service';
import { UserSandboxService } from '@shared-services/user-sandbox.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import { UserRoleType } from '@shared-models/user-role-type';
import { PaginationRequest } from '@shared-models/pagination-request';

@Component({
  selector: 'app-part-number-landing-page',
  templateUrl: './part-number-landing-page.component.html',
  styleUrls: ['./part-number-landing-page.component.scss']
})
export class PartNumberLandingPageComponent implements OnInit, OnDestroy {
  partNumberRevision: PartNumberRevision;
  nav: Navigation;
  UserRoleType = UserRoleType;
  user$ = this.userSandboxService.user$;
  user: User;
  addFileAssociationSuccess$ = this.partNumberRevisionSandboxService.addFileAssociationSuccess$;
  validators$ = this.userSandboxService.validators$;
  destroy$ = new Subject<boolean>();
  FileReviewSectionType = FileReviewSectionType;
  filesToValidate$ = this.partNumberRevisionSandboxService.filesToValidate$;
  replaceAndAddFileAssociationSuccess$ = this.partNumberRevisionSandboxService.replaceAndAddFileAssociationSuccess$;
  filesToValidateWithTotal: FileAssociation = undefined;
  filesOriginatedPendingValidation$ = this.partNumberRevisionSandboxService.filesOriginatedPendingValidation$;
  filesOriginatedPendingValidationWithTotal: FileAssociation = undefined;
  originatorFiles$ = this.partNumberRevisionSandboxService.originatorFiles$;
  originatorPastFiles$ = this.partNumberRevisionSandboxService.originatorPastFiles$;
  activeAssociatedFlilesWithTotal: FileAssociation = undefined;
  pastAssociatedFilesWithTotal: FileAssociation = undefined;
  showFilesToValidateLoader = true;
  showFilesOriginatedPendingValidationLoader = true;
  showOriginatorFilesLoader = true;
  faArrowLeft = faArrowLeft;
  validators: User[] = [];
  isValidator = false;

  constructor(
    private router: Router,
    private userSandboxService: UserSandboxService,
    private partNumberRevisionSandboxService: PartNumberRevisionSandboxService
  ) {
    this.nav = this.router.getCurrentNavigation();
  }

  ngOnInit(): void {
    if (this.nav && this.nav.extras && this.nav.extras.state && this.nav.extras.state.partNumberDetails) {
      this.partNumberRevision = this.nav.extras.state.partNumberDetails as PartNumberRevision;
    } else {
      const selectedPartNumberRevision = window.localStorage.getItem('selectedPartNumberRevision');
      this.partNumberRevision = JSON.parse(selectedPartNumberRevision);
    }

    this.userSandboxService.getValidators();

    this.user$
      .pipe(takeUntil(this.destroy$))
      .subscribe((user: User) => {
        if (user !== undefined) {
          this.user = user;
          this.isValidator = user.roles.includes(UserRoleType.Validator);

          if (this.isValidator) {
            this.partNumberRevisionSandboxService.getFilesToValidateSection(this.partNumberRevision);
          }

          this.partNumberRevisionSandboxService.getFilesOriginatedPendingValidationSection(this.partNumberRevision);
          this.partNumberRevisionSandboxService.getOriginatorActiveFilesSection(this.partNumberRevision);
          this.partNumberRevisionSandboxService.getOriginatorPastFilesSection(this.partNumberRevision);
        }
    });

    this.validators$
      .pipe(takeUntil(this.destroy$))
        .subscribe((response: User[]) => {
          if(response !== undefined) {
            for (const v of response) {
              const validator =  this.buildUserObject(v);
              this.validators.push(validator);
            }
            this.validators.sort((current, next)=>{ return current.userName.localeCompare(next.userName);});
          }
    });

    this.filesToValidate$
      .pipe(takeUntil(this.destroy$))
      .subscribe((fileAssociationDetails: FileAssociation) => {
        this.showFilesToValidateLoader = false;
        this.filesToValidateWithTotal = fileAssociationDetails;
    });

    this.filesOriginatedPendingValidation$
      .pipe(takeUntil(this.destroy$))
      .subscribe((fileAssociationDetails: FileAssociation) => {
        this.showFilesOriginatedPendingValidationLoader = false;
        this.filesOriginatedPendingValidationWithTotal = fileAssociationDetails;
    });

    this.originatorFiles$
      .pipe(takeUntil(this.destroy$))
      .subscribe((fileAssociationDetails: FileAssociation) => {
        this.showOriginatorFilesLoader = false;
        this.activeAssociatedFlilesWithTotal = fileAssociationDetails;
    });

      this.originatorPastFiles$
      .pipe(takeUntil(this.destroy$))
      .subscribe((fileAssociationDetails: FileAssociation) => {
        this.pastAssociatedFilesWithTotal = fileAssociationDetails;
    });

    this.addFileAssociationSuccess$
      .pipe(takeUntil(this.destroy$))
      .subscribe((status: any) => {
        this.showFilesToValidateLoader = false;
        this.showFilesOriginatedPendingValidationLoader = false;
        if (status) {
          this.refreshAllFileReviewSections();
        }
    });

    this.replaceAndAddFileAssociationSuccess$
      .pipe(takeUntil(this.destroy$))
      .subscribe((status: any) => {
        this.showOriginatorFilesLoader = false;
        if (status) {
          this.refreshAllFileReviewSections();
        }
    });
  }

  ngOnDestroy() {
    this.userSandboxService.clearValidatorListData();
    this.partNumberRevisionSandboxService.clearPartNumberRevisionsData();
    this.partNumberRevisionSandboxService.clearFilesToValidate();
    this.partNumberRevisionSandboxService.clearFilesOriginatedPendingValidationData();
    this.partNumberRevisionSandboxService.clearOriginatorFilesData();
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  refreshAllFileReviewSections() {
    this.partNumberRevisionSandboxService.getFilesToValidateSection(this.partNumberRevision);
    this.showFilesToValidateLoader = true;
    this.showFilesOriginatedPendingValidationLoader = true;
    this.partNumberRevisionSandboxService.getFilesOriginatedPendingValidationSection(this.partNumberRevision);
    this.showOriginatorFilesLoader = true;
    this.partNumberRevisionSandboxService.getOriginatorActiveFilesSection(this.partNumberRevision);
    this.partNumberRevisionSandboxService.getOriginatorPastFilesSection(this.partNumberRevision);
  }

  private buildUserObject(validator: User) {
    return {
      userName: this.formatUserName(validator.userName),
      email: validator.email,
      roles: validator.roles
    } as User;
  }

  private formatUserName(username: string) {
    const usernameArr = username.split(',');
    const firstName = usernameArr[1];
    const lastName = usernameArr[0];
    return `${firstName} ${lastName}`;
  }

  onValidationPagingTraversal(paginationRequest: PaginationRequest){
    const updatedPartNumberRevision = <PartNumberRevision>{ };
    const partNumberRevision = <PartNumberRevision>{ };

    updatedPartNumberRevision.partName = this.partNumberRevision.partName;
    updatedPartNumberRevision.partNumber = this.partNumberRevision.partNumber;
    updatedPartNumberRevision.productDescription = this.partNumberRevision.productDescription;
    updatedPartNumberRevision.productRevision = this.partNumberRevision.productRevision;
    updatedPartNumberRevision.pageIndex = paginationRequest.pageIndex;

    Object.assign(partNumberRevision, updatedPartNumberRevision);
    this.partNumberRevisionSandboxService.getFilesToValidateSection(partNumberRevision);
  }

  onOriginatedPagingTraversal(paginationRequest: PaginationRequest){
    const updatedPartNumberRevision = <PartNumberRevision>{ };
    const partNumberRevision = <PartNumberRevision>{ };

    updatedPartNumberRevision.partName = this.partNumberRevision.partName;
    updatedPartNumberRevision.partNumber = this.partNumberRevision.partNumber;
    updatedPartNumberRevision.productDescription = this.partNumberRevision.productDescription;
    updatedPartNumberRevision.productRevision = this.partNumberRevision.productRevision;
    updatedPartNumberRevision.pageIndex = paginationRequest.pageIndex;

    Object.assign(partNumberRevision, updatedPartNumberRevision);
    this.partNumberRevisionSandboxService.getFilesOriginatedPendingValidationSection(partNumberRevision);
  }

  onActiveAssociatedFilesTraversal(paginationRequest: PaginationRequest){
    const updatedPartNumberRevision = <PartNumberRevision>{ };
    const partNumberRevision = <PartNumberRevision>{ };

    updatedPartNumberRevision.partName = this.partNumberRevision.partName;
    updatedPartNumberRevision.partNumber = this.partNumberRevision.partNumber;
    updatedPartNumberRevision.productDescription = this.partNumberRevision.productDescription;
    updatedPartNumberRevision.productRevision = this.partNumberRevision.productRevision;
    updatedPartNumberRevision.pageIndex = paginationRequest.pageIndex;

    Object.assign(partNumberRevision, updatedPartNumberRevision);
    this.partNumberRevisionSandboxService.getOriginatorActiveFilesSection(partNumberRevision);
  }
  onPastAssociatedFilesTraversal(paginationRequest: PaginationRequest){
    const updatedPartNumberRevision = <PartNumberRevision>{ };
    const partNumberRevision = <PartNumberRevision>{ };

    updatedPartNumberRevision.partName = this.partNumberRevision.partName;
    updatedPartNumberRevision.partNumber = this.partNumberRevision.partNumber;
    updatedPartNumberRevision.productDescription = this.partNumberRevision.productDescription;
    updatedPartNumberRevision.productRevision = this.partNumberRevision.productRevision;
    updatedPartNumberRevision.pageIndex = paginationRequest.pageIndex;

    Object.assign(partNumberRevision, updatedPartNumberRevision);
    this.partNumberRevisionSandboxService.getOriginatorPastFilesSection(partNumberRevision);
  }
}
