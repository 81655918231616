import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { PartNumberRevisionSandboxService } from '@shared-services/part-number-revision-sandbox.service';
import { FileMetaData } from '@shared-models/file-meta-data';
import { FileUploadDto } from '@shared-models/file-upload.dto';
import { SupportedFileType } from '@shared-models/supported-file-type';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { WorkflowDetails, WorkflowRequest } from '@shared-models/workflow-request';
import { ActionType } from '@shared-models/action-type';
import { WorkflowType } from '@shared-models/workflow-type';
import { FileSelectionData } from './file-selection-data';
import { UploadStatus } from '@shared-models/upload-status';
import { HttpEventType, HttpHeaders } from '@angular/common/http';
import { UploadUrlInfo } from '@shared-models/upload-url-info';
import { ApiError } from '@shared-models/api-error';
import { UploadService } from '@shared-api-services/upload-service';
import { FileUploadStatusType } from '@shared-models/file-upload-status-type';
import { FileAssociation } from '@shared-models/file-association';

const file_chunk_size = 1024 * 1024 * 100; // 100 MB
@Component({
  selector: 'app-file-selection',
  templateUrl: './file-selection.component.html',
  styleUrls: ['./file-selection.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FileSelectionComponent implements OnInit, OnDestroy {
  @ViewChild('validatorSelection') validatorSelection: MatSelect;
  @Output() onReplaceAndAddFileAssociationComplete = new EventEmitter();
  filesToValidate$ = this.partNumberRevisionSandboxService.filesToValidate$;
  addFileAssociationSuccess$ = this.partNumberRevisionSandboxService.addFileAssociationSuccess$;
  replaceAndAddFileAssociationSuccess$ = this.partNumberRevisionSandboxService.replaceAndAddFileAssociationSuccess$;
  destroy$ = new Subject<boolean>();
  acceptedFileTypes = [SupportedFileType.Bpm, SupportedFileType.Csv, SupportedFileType.Txt, SupportedFileType.Xml];
  fileList: File[] = [];
  fileMetaDataList: FileMetaData[] = [];
  disableAssociationButton = true;
  faTimes = faTimes;
  message = '';
  currentRequest: any;
  s3multipleRequests = new Subscription();
  fileUpload: UploadStatus;
  FILE_UPLOAD_ID = '';
  FILE_UPLOAD_KEY = '';
  NUMBER_OF_CHUNKS = 0;
  uploadPercent = 0;
  multipartArray = [];
  uploadPartsArray = [];
  FileUploadStatusType = FileUploadStatusType;
  uploadAndAssociationComplete = false;
  uploadInProgress = false;
  hasError = false;
  partsLoaded = [];
  validatorFormGroup = new FormGroup({
    validatorSelection: new FormControl('', [Validators.required])
  });
  invalidFileNameMessage = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: FileSelectionData,
    private fileSelectionDialog: MatDialogRef<FileSelectionComponent>,
    private partNumberRevisionSandboxService: PartNumberRevisionSandboxService,
    private uploadService: UploadService
  ) { }

  ngOnInit(): void {
    this.addFileAssociationSuccess$
      .pipe(takeUntil(this.destroy$))
      .subscribe((status: boolean) => {
        if (status) {
          this.message = `File Upload and Association is complete. To upload another file remove the current file, or close this window if you are done with file association.`;
          this.uploadAndAssociationComplete = true;
          if (this.validatorSelection) {
            this.validatorSelection.disabled = false;
          }
          this.partNumberRevisionSandboxService.clearAddFileAssociationSuccessData();
        }
      });

    this.replaceAndAddFileAssociationSuccess$
      .pipe(takeUntil(this.destroy$))
      .subscribe((status: boolean) => {
        if (status) {
          this.onReplaceAndAddFileAssociationComplete.emit();
        }
      });

    this.filesToValidate$
      .pipe(takeUntil(this.destroy$))
      .subscribe((fileAssociationDetails: FileAssociation) => {
        this.data.filesToValidate = fileAssociationDetails?.data?.fileList;
      });
  }

  prepareFile(file: File) {
    this.fileList.push(file);

    if (!this.isValidFileName(file)) {
      this.message = this.invalidFileNameMessage;
      this.hasError = true;
    } else if (this.isFileAlreadyQueued(file)) {
      this.message = 'The selected file is already queued for validation.';
      this.hasError = true;
    } else if (!this.validatorSelection.value || this.validatorSelection.value === '') {
      this.message = 'Please select a Validator to add a new association.';
    } else {
      this.disableAssociationButton = false;
    }
  }

  isFileAlreadyQueued(file: File) {
    return this.data.filesToValidate?.some(element => element.fileName === file.name);
  }

  isValidFileName(file: File): boolean {
    const fileNameString = file.name.toLocaleLowerCase();
    const fileNameArray = fileNameString.split(/[_.]+/);
    const partNumber = this.data.partNumberRevision.partNumber.toLocaleLowerCase();

    const isPartNumberInFileName = fileNameArray?.some(element => element === partNumber);

    if (!isPartNumberInFileName) {
      this.invalidFileNameMessage = `The file you selected doesn't match the Part Number.`;
    }

    return isPartNumberInFileName;
  }

  clearUpload(removedFile: File) {
    this.message = '';
    this.validatorSelection.value = '';
    this.hasError = false;

    this.fileList.forEach((element, key) => {
      if (element.name === removedFile.name) {
        this.fileList.splice(key, 1);
      }
    });

    if (this.fileList.length === 0) {
      this.disableAssociationButton = true;
    }
  }

  onValidatorSelectChange() {
    if (this.fileList.length > 0 && this.isValidFileName(this.fileList[0]) && !this.isFileAlreadyQueued(this.fileList[0])) {
      this.disableAssociationButton = false;
      this.message = '';
      this.hasError = false;
    }
  }

  addFileAssociation() {
    this.disableAssociationButton = true;
    this.validatorSelection.disabled = true;

    if (this.data.fileAssociationDetail?.fileName && this.data.fileAssociationDetail?.fileName !== '') {
      this.replaceFileAssociation();
    } else {
      this.addNewFileAssociation();
    }
  }

  replaceFileAssociation() {
    for (const file of this.fileList) {
      const userWhoInitiatedReplaceAction = this.data.user.email;
      const workflowDetails: WorkflowDetails[] = [];
      const workflowDetail = {
        fileId: this.data.fileAssociationDetail.fileId,
        workflowHeaderId: this.data.fileAssociationDetail.workflowHeaderId,
        partNumber: this.data.partNumberRevision.partNumber,
        fileName: this.data.fileAssociationDetail.fileName,
        validator: this.validatorSelection.value,
        originator: userWhoInitiatedReplaceAction
      } as WorkflowDetails;

      workflowDetails.push(workflowDetail);

      const workflowRequest = {
        actionType: ActionType.Inactivated,
        workflowType: WorkflowType.Replace,
        workflowDetails
      } as WorkflowRequest;


      const fileMetaData = {
        fileName: file.name,
        partNumber: this.data.partNumberRevision.partNumber,
        partName: this.data.partNumberRevision.partName,
        productDescription: this.data.partNumberRevision.productDescription,
        revision: this.data.partNumberRevision.productRevision,
        originator: this.data.user.email,
        validator: this.validatorSelection.value
      } as FileMetaData;

      const fileUploadDto = {
        file,
        fileMetaData,
        workflowRequest,
        operation: WorkflowType.Replace
      } as FileUploadDto;

      this.uploadAndAssociateFile(fileUploadDto);
    }
  }

  addNewFileAssociation() {
    for (const file of this.fileList) {
      const fileMetaData = {
        fileName: file.name,
        partNumber: this.data.partNumberRevision.partNumber,
        partName: this.data.partNumberRevision.partName,
        productDescription: this.data.partNumberRevision.productDescription,
        revision: this.data.partNumberRevision.productRevision,
        originator: this.data.user.email,
        validator: this.validatorSelection.value
      } as FileMetaData;

      const fileUploadDto = {
        file,
        fileMetaData,
        operation: WorkflowType.Add
      } as FileUploadDto;

      this.uploadAndAssociateFile(fileUploadDto);
    }
  }

  private uploadAndAssociateFile(fileUploadDto: FileUploadDto) {
    this.uploadAndAssociationComplete = false;
    this.uploadInProgress = true;
    this.fileUpload = {
      message: 'Upload Started',
      status: FileUploadStatusType.Started,
      progress: 0
    };

    const headers = new HttpHeaders().set('Content-Type', this.fileList[0]['type']);
    const fileData = {
      fileName: this.fileList[0]['name'],
      fileType: this.fileList[0]['type'],
      partNumber: fileUploadDto.fileMetaData.partNumber
    };

    this.currentRequest = this.uploadService.getUploadURL(fileData, headers)
      .pipe(takeUntil(this.destroy$))
      .subscribe((urlInfo: UploadUrlInfo) => {
        const urlInfoData = JSON.parse(JSON.stringify(urlInfo));
        this.FILE_UPLOAD_ID = urlInfoData['uploadUrl']['uploadId'];
        this.FILE_UPLOAD_KEY = urlInfoData['uploadUrl']['key'];
        this.uploadMultipartFile(fileUploadDto);
      }, (error: { error: ApiError }) => {
        this.setUploadFailedMessage();
      });
  }

  async uploadMultipartFile(fileUploadDto: FileUploadDto) {
    this.NUMBER_OF_CHUNKS = Math.floor(this.fileList[0].size / file_chunk_size) + 1;
    let start;
    let end;

    for (let index = 1; index < this.NUMBER_OF_CHUNKS + 1; index++) {
      let blob;
      if (this.fileList[0] instanceof Blob) {
        start = (index - 1) * file_chunk_size;
        end = (index) * file_chunk_size;
        blob = (index < this.NUMBER_OF_CHUNKS) ? this.fileList[0].slice(start, end) : this.fileList[0].slice(start);
      } else {
        this.setUploadFailedMessage();
        return;
      }

      const params = {
        fileName: fileUploadDto.fileMetaData.fileName,
        partNumber: index,
        uploadId: this.FILE_UPLOAD_ID,
        key: this.FILE_UPLOAD_KEY
      };
      
      this.currentRequest = await this.uploadService.getMultipartURL(params)
        .then((fileUrl: { data: string }) => {
          this.uploadBlobToS3(fileUrl.data['uploadUrl'], blob, fileUploadDto, index);
        }, error => {
          this.setUploadFailedMessage();
        });
    }
  }

  uploadBlobToS3(urlInfo, blob, fileUploadDto: FileUploadDto, partNumberIndex: number) {
    this.multipartArray = [];

    this.s3multipleRequests.add(this.uploadService.uploadBlob(urlInfo, blob, fileUploadDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res.type === HttpEventType.UploadProgress) {
          let loadedTotal = 0;
          this.partsLoaded['part_' + partNumberIndex] = res.loaded;

          // eslint-disable-next-line guard-for-in
          for (const part in this.partsLoaded) {
            loadedTotal += this.partsLoaded[part];
          }

          const totalFileSize = this.fileList[0].size;
          this.uploadPercent = Math.round(loadedTotal / totalFileSize * 100);

          if (this.fileUpload.progress < this.uploadPercent && (this.uploadPercent !== 100)) {
            this.fileUpload = {
              status: FileUploadStatusType.InProgress,
              progress: this.uploadPercent
            };
          }
        } else if (res.type === HttpEventType.Response) {
          this.multipartArray.push(res);

          if (this.uploadPercent !== 100) {
            this.fileUpload = {
              message: 'Upload is in progress.',
              status: FileUploadStatusType.InProgress,
              progress: this.uploadPercent
            };
          }

          if (this.multipartArray.length === this.NUMBER_OF_CHUNKS) {
            this.fileUpload = {
              message: 'Your file was uploaded successfully. Finalizing the upload process now.',
              status: FileUploadStatusType.InProgress,
              progress: 99 // This is set to show 99 since we have a subsequent API call to complete which adds a bit of delay to complete.
            };

            this.completeUploadAndPostFileData(fileUploadDto);
          }
        }
      }, error => {
        this.s3multipleRequests.unsubscribe();
        this.s3multipleRequests = new Subscription();
        this.fileUpload = {
          message: 'An error occurred with your upload. Please try again.',
          status: FileUploadStatusType.Failed,
          progress: 0
        };
        this.uploadInProgress = false;
        this.uploadAndAssociationComplete = true;
        this.validatorSelection.disabled = false;
      }));
  }

  completeUploadAndPostFileData(fileUploadDto: FileUploadDto) {
    if (this.multipartArray.length > 0) {
      this.multipartArray.forEach((resolvedPromise, index) => {
        const URLparam = new URLSearchParams(resolvedPromise.url);
        this.uploadPartsArray[parseInt(URLparam.get('partNumber'), 10) - 1] = {
          ETag: resolvedPromise.headers.get('etag'),
          PartNumber: parseInt(URLparam.get('partNumber'), 10)
        };
      });
    }

    const body = {
      key: this.FILE_UPLOAD_KEY,
      partNumbers: this.uploadPartsArray,
      uploadId: this.FILE_UPLOAD_ID
    };

    this.currentRequest = this.uploadService.completeUpload(body)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.uploadPartsArray = [];
        this.uploadInProgress = false;
        this.partsLoaded = [];
        this.postFileData(fileUploadDto);
      }, error => {
        this.setUploadFailedMessage();
        this.partsLoaded = [];
      }
      );
  }

  postFileData(fileUploadDto: FileUploadDto) {
    if (fileUploadDto.operation === WorkflowType.Add) {
      this.partNumberRevisionSandboxService.addFileAssociation(fileUploadDto);
    } else if (fileUploadDto.operation === WorkflowType.Replace) {
      this.partNumberRevisionSandboxService.replaceAndAddFileAssociation(fileUploadDto);
    }
  }

  closeDialog() {
    if (this.uploadInProgress) {
      const willClose = confirm('Are you sure? You might lose upload progress if you close this window.');

      if (willClose) {
        if (this.currentRequest) {
          this.currentRequest.unsubscribe();
        } else {
          this.s3multipleRequests.unsubscribe();
          this.s3multipleRequests = new Subscription();
        }
        this.uploadAndAssociationComplete = true;
        this.disableAssociationButton = false;
        this.fileUpload = {
          message: 'File Upload Canceled',
          status: FileUploadStatusType.Canceled,
          progress: 0
        };
        this.uploadInProgress = false;
        this.fileSelectionDialog.close();
        this.partsLoaded = [];
      }
    } else if (this.uploadAndAssociationComplete) {
      this.fileSelectionDialog.close('refresh');
    } else {
      this.fileSelectionDialog.close();
    }
  }

  setUploadFailedMessage() {
    this.message = 'Failed to upload the file. Please retry the upload.';
    this.uploadAndAssociationComplete = true;
    this.validatorSelection.disabled = false;
  }

  ngOnDestroy() {
    this.partNumberRevisionSandboxService.clearReplaceAndAddFileAssociationSuccessData();
    this.partNumberRevisionSandboxService.clearAddFileAssociationSuccessData();

    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
